import React from 'react'
import App from 'next/app'
import NextHead from 'next/head'

import { getRootStore } from '~/stores/RootStore'
import { Provider } from 'mobx-react'
import { getTokenData } from '~/helpers/cookieHelper'
import withGA from 'next-ga'
import Router from 'next/router'
import stringify from 'json-stringify-safe'
import isDevelopment from '~/utils/isDevelopment'
import isServer from '~/utils/isServer'
import { router } from '~/lib/router'
import { CONFIG, GA_CODE } from '~/global.config'
import { SEO } from '~/components/Meta'

import './_app.scss'

class NextJSMobxApp extends App {
  static async getInitialProps(appContext) {
    const cookieObject = appContext.ctx.req
      ? getTokenData(appContext.ctx.req.headers.cookie) // Server side
      : getTokenData(document.cookie)

    // ? On server-side, this runs once and creates new stores
    const rootStore = getRootStore({ authStore: cookieObject })

    // ? Make stores available to page's `getInitialProps`
    appContext.ctx.rootStore = rootStore

    // ? Call "super" to run page's `getInitialProps`
    const appProps = await App.getInitialProps(appContext)

    // ? Generate a serialized RootStore to pass below (in return)
    // ? __NEXT_DATA
    const rootStoreString = stringify(rootStore, null)

    return {
      ...appProps,
      currentRoute: router.getCurrentRoute(appContext.ctx),
      rootStoreString,
    }
  }

  constructor(props) {
    super(props)
    const { rootStoreString } = props
    // ? Pull rootStoreString from __NEXT_DATA__, deserialize, and pass to client-side
    // ? RootStore
    let serializer
    stringify.getSerialize(serializer)
    const rootStore = JSON.parse(rootStoreString, serializer)
    this.rootStore = getRootStore(rootStore)
  }

  componentDidMount() {
    if (!isServer && isDevelopment) {
      global.window.rootStore = this.rootStore
      global.window.currentRoute = router.getCurrentRoute()
    }
  }

  render() {
    const { Component, pageProps, currentRoute } = this.props

    return (
      <>
        <NextHead>
          <meta charSet='UTF-8' />
          <title>{SEO.title}</title>
          <meta name='description' content={SEO.description} />
          <link href='https://fonts.googleapis.com/css2?family=Inter:wght@200;400;800&display=swap' rel='stylesheet' />

          <link rel='apple-touch-icon' sizes='57x57' href='/static/favicon/apple-icon-57x57.png' />
          <link rel='apple-touch-icon' sizes='60x60' href='/static/favicon/apple-icon-60x60.png' />
          <link rel='apple-touch-icon' sizes='72x72' href='/static/favicon/apple-icon-72x72.png' />
          <link rel='apple-touch-icon' sizes='76x76' href='/static/favicon/apple-icon-76x76.png' />
          <link rel='apple-touch-icon' sizes='114x114' href='/static/favicon/apple-icon-114x114.png' />
          <link rel='apple-touch-icon' sizes='120x120' href='/static/favicon/apple-icon-120x120.png' />
          <link rel='apple-touch-icon' sizes='144x144' href='/static/favicon/apple-icon-144x144.png' />
          <link rel='apple-touch-icon' sizes='152x152' href='/static/favicon/apple-icon-152x152.png' />
          <link rel='apple-touch-icon' sizes='180x180' href='/static/favicon/apple-icon-180x180.png' />
          <link rel='icon' type='image/png' sizes='192x192' href='/static/favicon/android-icon-192x192.png' />
          <link rel='icon' type='image/png' sizes='32x32' href='/static/favicon/favicon-32x32.png' />
          <link rel='icon' type='image/png' sizes='96x96' href='/static/favicon/favicon-96x96.png' />
          <link rel='icon' type='image/png' sizes='16x16' href='/static/favicon/favicon-16x16.png' />
          <link rel='manifest' href='/static/favicon/manifest.json' />
          <meta name='msapplication-TileColor' content='#ffffff' />
          <meta name='msapplication-TileImage' content='/static/favicon/ms-icon-144x144.png' />
          <meta name='theme-color' content='#ffffff' />
        </NextHead>
        <Provider rootStore={this.rootStore}>
          <Component
            {...pageProps}
            currentRoute={currentRoute}
          />
        </Provider>
      </>
    )
  }
}

const HOCWrapper = (app) => {
  const wrappedApp = app
  const { withGoogleAnalytics } = CONFIG

  if (withGoogleAnalytics) {
    withGA(GA_CODE, Router)(wrappedApp)
  }

  return wrappedApp
}

export default HOCWrapper(NextJSMobxApp)
