/**
 * Stores a value in the localStorage.
 * @function
 * @usage `import { storageSet } from '~/helpers/storageHelper'`
 * @param {object} objectToSet - The object containing the values to store | Example: { A: 1, B: 2 }
 */
export const storageSet = (objectToSet) => {
  Object.keys(objectToSet).forEach(key => {
    localStorage.setItem(`_${key}`, objectToSet[key])
  })
}

/**
 * Retrieve a value from the localStorage.
 * @function
 * @usage `import { storageGet } from '~/helpers/storageHelper'`
 * @param {string} key - The value key to retrieve
 * @returns {string}
 */
export const storageGet = (key) => {
  return localStorage.getItem(`_${key}`)
}

/**
 * Remove a value from the localStorage.
 * @function
 * @usage `import { storageRemove } from '~/helpers/storageHelper'`
 * @param {string} key - The value key to remove
 * @returns {string}
 */
export const storageRemove = (key) => {
  return localStorage.removeItem(`_${key}`)
}

/**
 * Clears the localStorage.
 * @function
 * @usage `import { storageClear } from '~/helpers/storageHelper'`
 */
export const storageClear = () => {
  localStorage.clear()
}
