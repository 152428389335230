import { action } from 'mobx'

export default class Store {
  constructor({ rootStore }) {
    this.rootStore = rootStore

    if (this.service) {
      this.serviceApis = this.service().api()
    }
  }

  setRootStore(rootStore) {
    this.rootStore = rootStore
  }

  api() {
    return this.serviceApis || {}
  }

  @action setObservable(stateObject) { // todo: Remove?
    Object.keys(stateObject).forEach(key => {
      this[key] = stateObject[key]
    })
  }
}
