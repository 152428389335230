import isServer from '~/utils/isServer'

import StateStore from './StateStore'

const STORES = {
  stateStore: StateStore,
}

let clientSideStores

export const getRootStore = (initialData = {}) => {
  const rootStore = {}
  if (isServer || !clientSideStores) {
    Object.keys(STORES).forEach(key => {
      rootStore[key] = new STORES[key]({ ...initialData[key], rootStore })

      Object.keys(rootStore).forEach(key => {
        rootStore[key].setRootStore(rootStore)
      })
    })
  }

  if (isServer) {
    return rootStore
  }

  if (!clientSideStores) {
    clientSideStores = rootStore
  }

  return clientSideStores
}
