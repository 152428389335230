import React from 'react'
import PropTypes from 'prop-types'
import NextRouter from 'next/router'
import NextLink from 'next/link'
import HttpStatus from 'http-status-codes'
import appRoutes from '~/routes'
import { isEmpty, trim, isObject } from 'lodash'
import regex from '~/utils/regex'
import isServer from '~/utils/isServer'

const createRoute = ({ href, params }) => {
  let templatePath = href
  let actualPath = href

  if (isEmpty(params)) {
    return { templatePath, actualPath }
  }

  templatePath = href.replace(regex(regex.patterns.paramsStrict), (variable) => {
    const variableTrimmed = trim(variable, ':')
    return `[${variableTrimmed}]`
  })

  actualPath = href.replace(regex(regex.patterns.paramsStrict), (variable) => {
    const variableTrimmed = trim(variable, ':')
    return `${params[variableTrimmed]}`
  })

  return { templatePath, actualPath }
}

const extractBasePath = (path, exp = regex.patterns.firstParamAndOnward) => {
  return path.replace(regex(exp), '')
}

/**
 * Push redirects server-side and client-side.
 *
 * @function
 * @usage `import { router } from '~/lib/router'`
 * @param {string} [href=''] - The path to redirect to
 * @param {object} [params={}] - The params that will be used in the path
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * router.push(router.routes.foobar, { id: 1, slug: 2 }, ctx)
 */
const routerPush = (href = '', params = {}, ctx = {}) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.push(templatePath, actualPath)
  }
}

/**
 * Replace redirects server-side and client-side.
 *
 * @usage `import { router } from '~/lib/router'`
 * @param {string} [href=''] - The path to redirect to
 * @param {object} [params={}] - The params that will be used in the path
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * router.replace(router.routes.foobar, { id: 1, slug: 2 }, ctx)
 */
const routerReplace = (href = '', params = {}, ctx = {}) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  if (ctx.res) {
    ctx.res.writeHead(HttpStatus.MOVED_TEMPORARILY, {
      Location: actualPath,
    })
    ctx.res.end()
  } else {
    NextRouter.replace(templatePath, actualPath)
  }
}


/**
 * Next Link with dynamic router.
 *
 * @component
 * @usage `import { Link } from '~/lib/router'`
 * @param {string|array} [href=''] - The path {string} or dynamic path {array} to redirect to
 * @example
 * ? Will redirect to /foobar/[id=1]/[slug=2]
 * <Link href={router.routes.foobar.path} params={{id: 1, slug: 2}}>
 *  Click me!
 * </Link>
 */
const Link = ({ href, params, children, ...extra }) => {
  const { templatePath, actualPath } = createRoute({ href, params })

  return (
    <NextLink href={templatePath} as={actualPath}>
      <a {...extra}>
        {children}
      </a>
    </NextLink>
  )
}

Link.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string, PropTypes.array,
  ]).isRequired,
  params: PropTypes.object,
}

Link.defaultProps = {
  href: '',
  params: {},
}

/**
 * Retrieves app routes or paths and presents them.
 *
 * @function
 * @param {object} routes - Object containing the app routes | Example: { home: '/home/:id' }
 * @returns {object} - | Example: { home: { path: '/home/:id', basePath: '/home', ... } }
 */
const presentRoutes = (routes) => {
  const routesPresented = {}
  const allRouteKeys = Object.keys(routes)

  allRouteKeys.forEach(routeKey => {
    let routeValue = routes[routeKey]

    if (!isObject(routeValue)) { // ? If `routeValue` is a string
      routeValue = { path: routeValue }
    }

    const basePath = extractBasePath(routeValue.path)
    routesPresented[routeKey] = {
      ...routeValue,
      basePath,
    }
  })

  return routesPresented
}

/**
 * Organizes app routes or paths by url rather than by key.
 *
 * @function
 * @param {object} baseRoutes - Object containing the *absolute* app routes | Example: { home: '/home' }
 * @returns {object} - | Example: { '/home': 'home' }
 */
const getRoutesByPath = (routesPresented) => {
  const reversedObject = {}
  Object.keys(routesPresented).forEach(routeKey => {
    const routeValue = routesPresented[routeKey]
    reversedObject[routeValue.basePath] = {
      key: routeKey,
      ...routeValue,
    }
  })

  return reversedObject
}

/**
 * Returns the current route and related parameters.
 *
 * @param {object} routesByPath - Object of routes organized by path instead of by key
 * @param {object} [ctx={}] - [Optional] The current context. To be used if there is a possibility of calling this function serverside
 */
const getCurrentRoute = (routesByPath, ctx = {}) => {
  if (!isEmpty(ctx)) {
    const basePath = extractBasePath(ctx.pathname, regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: ctx.asPath,
      query: ctx.query,
      ...routesByPath[basePath],
    }
  }

  if (!isServer) {
    const basePath = extractBasePath(NextRouter.route, regex.patterns.firstNextJsParamAndOnward)
    return {
      currentPath: NextRouter.asPath,
      query: NextRouter.query,
      ...routesByPath[basePath],
    }
  }

  return {}
}

const router = () => {}
const routesPresented = presentRoutes(appRoutes)
const routesByPath = getRoutesByPath(routesPresented)
router.replace = routerReplace
router.push = routerPush
router.routes = routesPresented
router.routesByPath = routesByPath
router.getCurrentRoute = (ctx) => getCurrentRoute(routesByPath, ctx)
export { router, Link }
