import Cookies from 'js-cookie'
import cookie from 'cookie'
import isServer from '~/utils/isServer'

const AUTH_COOKIE_KEY = 'AUTH_COOKIE_KEY'
const COOKIE_EXPIRES_IN_DAYS = 360

/**
 * Sets cookie.
 * @function
 * @usage `import { setCookie } from '~/helpers/cookieHelper'`
 * @param {object} name - Cookie name
 * @param {string} value - Cookie value; usually in stringified JSON format
 * @param {string} days - Number of days the cookie will remain valid for
 */
export const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toGMTString()}`
  }

  if (!isServer) {
    document.cookie = `${name}=${value}${expires};path=/`
  }
}

/**
 * Modifies cookie values.
 * @function
 * @usage `import { saveTokenData } from '~/helpers/cookieHelper'`
 * @param {JSON} tokenData - Full cookie data
 */
export const saveTokenData = tokenData => {
  setCookie(
    AUTH_COOKIE_KEY,
    tokenData && JSON.stringify(tokenData),
    COOKIE_EXPIRES_IN_DAYS
  )
}

/**
 * Clears cookie.
 * @function
 * @usage `import { clearTokenData } from '~/helpers/cookieHelper'`
 * @param {string} [cookieName=AUTH_COOKIE_KEY] - Cookie to clear
 */
export const clearTokenData = (cookieName = AUTH_COOKIE_KEY) => {
  Cookies.remove(cookieName)
}

/**
 * Parses raw cookie data and returns it.
 * @function
 * @usage `import { getTokenData } from '~/helpers/cookieHelper'`
 * @param {string} cookieData - Cookie value; usually in stringified JSON format
 * @returns {object}
 */
export const getTokenData = cookieData => {
  if (!cookieData) {
    return {}
  }

  const theCookie = cookie.parse(cookieData)[AUTH_COOKIE_KEY]

  try {
    return theCookie ? JSON.parse(theCookie) : {}
  } catch (err) {
    return theCookie
  }
}
