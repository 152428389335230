export const SEO = {
  title: 'Joseph\'s Piece of the Internet',
  description: 'Welcome to this Joseph\'s little piece of the interwebs!',
  url: 'https://www.joseph.codes/',
  image: '/static/images/dovahbear.png',
  tileColor: '#758898',
  tileImage: '/static/images/dovahbear.png',
  color: '#FF0000',
}

export const OG = {
  locale: 'en_US',
  type: 'website',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  siteName: SEO.title,
  image: SEO.image,
}

export const TWITTER = {
  card: 'summary',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  image: SEO.image,
}
