export const CONFIG = {
  withGoogleAnalytics: false,
}

export const API_BASE = ''
export const GA_CODE = 'UA-<google-analytics-id>-1'
export const FIREBASE = {
  apiKey: '<api-key>',
  authDomain: '<project-id>.firebaseapp.com',
  databaseURL: 'https://<project-id>.firebaseio.com',
  projectId: '<project-id>',
  storageBucket: '<project-id>.appspot.com',
  messagingSenderId: '<messaging-sender-id>',
  appId: '1:<messaging-sender-id>:web:<token>',
  measurementId: 'G-<measurement-id>',
}
