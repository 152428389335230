import Store from './Store'
import { observable, action, computed, toJS } from 'mobx'

import { storageSet, storageGet } from '~/helpers/storageHelper'
import isServer from '~/utils/isServer'

class StateStore extends Store {
  @observable _theme = 'light';

  constructor(initialData = {}) {
    super(initialData)

    this.predefineTheme()
  }

  predefineTheme() {
    if (!isServer) {
      const storageTheme = storageGet('theme')
      if (storageTheme && storageTheme !== 'null') {
        this.setTheme(storageTheme)
      }
    }
  }

  setThemeInStorage(theme = this.theme) {
    storageSet({ theme })
  }

  @action setTheme(value) {
    this._theme = value
    this.setThemeInStorage(value)
  }

  @action switchTheme() {
    this._theme = this.theme === 'light' ? 'dark' : 'light'
    this.setThemeInStorage()
  }

  @computed get theme() {
    return toJS(this._theme)
  }
}

export default StateStore
